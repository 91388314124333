<template>
    <div class="background-wrapper d-flex">
        <div class="logo-auth no-img d-none d-lg-block"></div>
        <div class="col-12 col-lg-10 p-0">
            <div class="event-page-wrapper dark-style">
                <div class="file-upload-section pt-10">
                    <img :src="preview_image['membership_header_image']" v-if="preview_image['membership_header_image'] != ''">
                    <div v-else class="show-upload-block form-group pt-0 closed-caption-upload-wrapper file-upload-vue-wrapper mb-0">
                        <div>
                            <a href="#" class="btn btn-primary displaybloc"><upload class="svg-24"></upload> Upload Image</a>
                            <div class="image-description">
                                <p>Must be 1440 X 640 pixels</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main-content-area row">
                    <div class="col-12 col-md-8">
                        <h1 class="mb-2 border-bottom-1-grey5">{{ orderinfo.event_title }}</h1>
                        <div class="d-flex justify-content-between align-top">
                            <div>
                                <div class="field-group">
                                    <label>Event Type</label>
                                    <div v-if="orderinfo.include_associate_members == 0">{{ 'FYC - ' + 'Open to All Active Members' }}</div>
                                    <div v-else>{{ 'FYC - ' + 'Open to All Members' }}</div>
                                </div>
                            </div>
                        </div>

                        <div class="mt-0 mb-4 w-100 d-flex justify-content-between justify-content-sm-start">
                            <div class="pr-2 pr-lg-5 field-group">
                                <label>Date</label>
                                <div>{{ event_date_display }}</div>
                            </div>
                            <div class="mx-1 mx-sm-3 mx-lg-4 field-group">
                                <label>Start Time</label>
                                <div class="text-uppercase">{{ orderinfo.time_slot == 'Open' ? orderinfo.time_slot_other : orderinfo.time_slot }} </div>
                            </div>
                        </div>

                        <div class="event-description-wrapper">
                            <event-webpage-section title="About The Event" :content="orderinfo.invite_description" :cohosted="orderinfo.cohosted" :cohosted_with="orderinfo.cohosted_with" :cohosted_with_details="orderinfo.cohosted_with_details" :event_page_additional_content="orderinfo.event_page_additional_content" :type="orderinfo.type"></event-webpage-section>

                            <event-webpage-section-venue title="Venue Details" :orderinfo="orderinfo"></event-webpage-section-venue>

                            <event-webpage-section-additional title="Additional Event Information" :orderinfo="orderinfo" v-if="orderinfo.type != 'Virtual'"></event-webpage-section-additional>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <h2 class="light rsvp">RSVP</h2>
                        <a href="#" class="btn btn-primary uppercase btn-no-hover no-click font-weight-bold f-14 w-200px" @click.prevent="" v-if="orderinfo.type == 'Virtual'">Member RSVP</a>
                        <a href="#" class="btn btn-primary uppercase w-100 btn-no-hover no-click font-weight-bold f-14 w-200px" @click.prevent="" v-else>Enter the Lottery</a>
                    </div>


                </div>

            </div>
        </div>
    </div>
</template>

<script>
import EventWebpageSection from "@/components/Events/EventWebpageSection.vue";
import EventWebpageSectionVenue from "@/components/Events/EventWebpageSectionVenue.vue";
import EventWebpageSectionAdditional from "@/components/Events/EventWebpageSectionAdditional.vue";

export default {
    components: {
        EventWebpageSection,
        EventWebpageSectionVenue,
        EventWebpageSectionAdditional,
    },
    props: {
        event: Array,
    },
    data() {
        return {
            orderinfo: this.event,
            event_date_display: '',
            preview_image: {
                'membership_header_image': this.event.membership_header_image != '' ? this.event.membership_header_image : '',
            }
        }
    },
    mounted() {
        if(this.orderinfo.event_date) {
            // Set event_date to m-d-Y format
            let eventDateTemp = this.orderinfo.event_date.replace(/-/g, '/');
            let eventDate = new Date(eventDateTemp);
            this.event_date_display = eventDate.toLocaleString('en-us', { weekday:"long", year:"numeric", month:"long", day:"numeric"});
        }
    }
}

</script>
