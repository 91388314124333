<template>
    <div>
        <div @click="collapsed = !collapsed" class="cursor-pointer profile-accordion border-bottom-1-grey6 d-flex align-items-center justify-content-between mb-3 toggle-with-up-icon text-decoration-none">
            <h3 class="primary mb-0">{{ title }}</h3>
            <down-chevron-icon class="float-end svg-24 svg-accent" v-if="collapsed"></down-chevron-icon>
            <up-chevron-icon class="float-end svg-24 svg-accent" v-else></up-chevron-icon>
        </div>

        <div class="collapse show profile-info-wrapper" id="collapseAboutEvent" v-show="!collapsed">
            <div class="event-accordion-content-wrapper mb-4">
                <h3>Accessibility</h3>
                <div><p>The Television Academy is committed to making member events accessible to everyone. For information about accessibility at FYC venues, <a href="https://emmys.com/accessibility/venues" target="_blank" rel="noopener noreferrer">click here</a>.</p></div>
                <div>
                    <p v-if="orderinfo.ada_invitation_copy" v-text="orderinfo.ada_invitation_copy"></p>
                    <p v-if="orderinfo.ada_contact && orderinfo.wolf_theatre == 0">
                        For questions about accessibility at this event, contact <a :href="'mailto:' + orderinfo.ada_contact" target="_blank" class="no-click no-hover" @click.prevent="">{{ orderinfo.ada_contact }}</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UpChevronIcon from "@/Icons/up-chevron.vue";
import DownChevronIcon from "@/Icons/down_chevron.vue";

export default {
    components: {
        DownChevronIcon,
        UpChevronIcon
    },
    props: {
        title: String,
        orderinfo: Array
    },
    data() {
        return {
            collapsed: false
        }
    }
}

</script>
